

export const dateSuffix = function (number) {
    var d = number % 10;
    return (~~ (number % 100 / 10) === 1) ? 'th' :
        (d === 1) ? 'st' :
        (d === 2) ? 'nd' :
        (d === 3) ? 'rd' : 'th';
}

export const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
];

export const daysShort = [
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sun'
];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export function isNumeric(value) {
    const allowedTypes = ['string', 'number'];
    return allowedTypes.includes(typeof value) && value.toString() && ! isNaN(value)
}


export function parseKeyValuesToInt(object, keys) {
    for (let key of keys) {
        object[key] = parseInt(object[key]);
    }
    return object;
}

/**
 * Finds the largest number in an array
 * @param array
 * @return int
 */
export function getLargestNumber(array) {
    return Math.max.apply(0, array);
}

/**
 * Used to set default config setting if not set on the window object
 * @param {object} config
 * @param {object} defaults
 * @return {object} config
 */
export function setDefaultsIfNotSet(config, defaults) {
    for (let setting in defaults) {
        if (config[setting] == undefined) {
            config[setting] = defaults[setting];
            continue;
        }
        const isObject = (
            typeof config[setting] == 'object' &&
            ! Array.isArray(config[setting])
        );
        if (isObject) {
            config[setting] = setDefaultsIfNotSet(config[setting], defaults[setting]);
        }
    }

    return config;
}

/**
 * Re-orders days of week based on the index passed.
 *
 * @param days {string[]} Must always be Mon-Sun
 * @param index {number}
 * @return {string[]}
 */
export function orderDaysStartingWithIndex(days, index)
{
    return days.slice(index).concat(days.slice(0, index));
}
