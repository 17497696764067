import { set as ObjectSet, has as  ObjectHas } from 'lodash';

(function () {
    if (document.querySelectorAll('[ibe-extra-id]').length == 0 || ! ObjectHas(window, 'ibe_extras')) {
        return;
    }
    if (! ObjectHas(window, 'ibe_extras.key')) {
        console.error('Config not set [window.ibe_extras.key]');
        return;
    }

    new Vue({
        el: '[ibe-extra-id]',
        data() {
            return {
                extra: '',
                key: '',
                id: ''
            }
        },
        watch: {
            /**
             * When the extras change, this will update the extras in the href link
             * @param newValue
             */
            extra: function (newValue) {
                let links = Array.from(
                    document.querySelectorAll(`[ibe-extra-link="${this.id}"]`)
                );
                links.forEach(link => {
                    const url = new URL(link.href);
                    let extras = url.searchParams.get('extras') ?? '{}';
                    extras = ObjectSet(
                        JSON.parse(extras),
                        this.key,
                        newValue
                    );
                    url.searchParams.set('extras', JSON.stringify(extras));
                    link.setAttribute('href', url.href);
                });
            }
        },
        computed: {
            config() {
                return window.ibe_extras;
            }
        },
        beforeMount() {
            this.extra = this.$el.value;
        },
        mounted() {
            this.id = this.$el.getAttribute('ibe-extra-id');
            this.key = this.config.key;
        }
    });
}());