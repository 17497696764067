<template>
    <div class="card-number">
        <input type="tel" class="form-control"
               :name="name + '-mask'"
               @input.prevent="handleSplitCardNumber($event)"
               v-model="cardNumber"
               :id="name">
        <input type="hidden" :name="name" :id="name + '-hidden'" :value="getCardNumberWithoutSpaces(cardNumber)">
    </div>
</template>

<script>
export default {
    name: 'card-number',
    data(props) {
        return {
            cardNumber: this.getCardNumberWithoutSpaces(props.value)
        }
    },
    props: {
        name: {
            type: [String, Number],
            default: 'creditCardNumber'
        },
        value: {
            type: [String, Number],
            default: ''
        }
    },
    methods: {
        /**
         * Formats the given number into the specified chunks
         * E.g To chunk a visa card type into 4s we'd pass the array as [4, 4, 4, 4]
         * This would chunk the card number like so "4263 9700 0000 5262"
         *
         * @param string cardNumber
         * @param array formatChunks
         * @return string
         */
        formatNumber(cardNumber, formatChunks) {
            let startingSliceIndex = 0;
            const numberChunks = formatChunks.map( count => {
                const chunk = cardNumber.slice(startingSliceIndex, startingSliceIndex + count);
                startingSliceIndex += count;
                return chunk;
            });
            return numberChunks.join(' ');
        },
        /**
         * @param string cardNumberWithoutSpaces
         */
        getFormattedCardNumber(cardNumberWithoutSpaces) {
            let formattedNumber = cardNumberWithoutSpaces;
            switch (true) {
                /**
                 * Visa, MasterCard and Discover cards
                 */
                case cardNumberWithoutSpaces.startsWith('4'):
                case Array.isArray(cardNumberWithoutSpaces.match('^(51|52|53|54|55|22|23|24|25|26|27)')):
                case Array.isArray(cardNumberWithoutSpaces.match('^(60|64|65|622)')):
                    formattedNumber = this.formatNumber(
                        cardNumberWithoutSpaces.slice(0, 16), [4, 4, 4, 4]
                    );
                break;
                /**
                 * AMEX Cards
                 */
                case Array.isArray(cardNumberWithoutSpaces.match('^(34|37)')):
                    formattedNumber = this.formatNumber(
                        cardNumberWithoutSpaces.slice(0, 15), [4, 6, 5]
                    );
                break;
            }


            return formattedNumber.trim();
        },
        /**
         * @param Event e
         */
        handleSplitCardNumber(e) {
            if (e.inputType == 'deleteContentBackward') {
                return;
            }
            let cardNumberWithoutSpaces = this.getCardNumberWithoutSpaces(this.cardNumber);
            this.cardNumber = this.getFormattedCardNumber(cardNumberWithoutSpaces);
        },
        /**
         * @param string cardNumber
         * @return string
         */
        getCardNumberWithoutSpaces(cardNumber) {
            return cardNumber.replace(new RegExp(/\D/g), '');
        },
    },
    beforeMount() {
        this.handleSplitCardNumber({});
    }
}
</script>
