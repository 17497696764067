import {dateSuffix, days, daysShort, months, monthsShort, setDefaultsIfNotSet} from './helpers';
import spacetime from "spacetime";
import { set } from 'lodash';


export default function () {
    let config = window.searchWidget;
    const errorStyle = 'font-size: 15px; background-color: #dc3545; color: #fff; padding: 5px;';

    // required search widget values go here
    const searchWidgetInterface = [
        'cities',
        'hotels'
    ];
    // if you want a required setting to be disabled by another
    const searchWidgetInterfaceOmitter = {
        // value removes -> key
        'disableCities': 'cities'
    };
    Object.keys(searchWidgetInterfaceOmitter).forEach( val => {
        if ( config[val] != undefined && config[val] == true) {
            const omit = searchWidgetInterfaceOmitter[val];
            const omitIndex = Object.values(searchWidgetInterface).indexOf(omit);
            searchWidgetInterface.splice(omitIndex, 1);
        }
    });
    // list of required setting not set in the config
    let undefinedConfigNames = [];
    searchWidgetInterface.forEach((requiredSearchConfigName) => {
        if ( ! config[requiredSearchConfigName]) {
            undefinedConfigNames.push(requiredSearchConfigName);
        }
    });
    // prints the required setting for the config to the console
    if (undefinedConfigNames.length) {
        undefinedConfigNames.forEach(undefinedConfigName => {
            console.error('%c "' + undefinedConfigName + '" is required on the "searchWidget" Object', errorStyle);
        });
    }

    const mapAvailabilitySearch = {
        'nights': 'selectedNights',
        'rooms': 'selectedRooms',
        'city': 'selectedCity',
        'hotel': 'selectedHotel',
        'code': 'selectedPromoCode',
        'codeType': 'selectedPromoType',
        'adults': 'selectedAdults',
        'children': 'selectedChildren',
        'childAges': 'childAges',
        'infants': 'selectedInfants',
        'arrival': 'datepickerConfig.arrival',
        'departure': 'datepickerConfig.departure',
    };

    /**
     * Set defaults
     */
    const defaultSettings = {
        occupancyUrl: '/api/hotel-occupancys',
        disableCities: false,
        disableOccupancyUrl: false,
        cities: [],
        hotels: [],
        maxAdults: 5,
        minAdults: 1,
        maxChildren: 4,
        maxInfants: 4,
        minChildren: 0,
        minInfants: 0,
        rooms: 10,
        nights: 10,
        selectedNights: 1,
        selectedRooms: 1,
        selectedHotel: 0,
        selectedCity: 0,
        /**
         * Object where the key represents the age which gets submitted in the form input
         * and the object value as the text which is display to the end user
         * {
         *     3: "Children from 0 to 3 years",
         *     16: "Children from 4 to 16 years"
         * }
         */
        childAgeGroups: {},
        /**
         * If the client wants the actual for each given child, then set the following config
         * to a number.
         * Defaults to null to disable
         */
        maxChildAges: null,
        availabilitySearch: {
            childAges: {}
        },
        selectedPromoCode: '',
        selectedPromoType: 'promo',
        /**
         * Date picker config
         */
        datepickerConfig: {
            arrival: new Date(),
            departure: null,
            mode: 'single',
            fullscreenMobile: false,
            hideActionButtons: false,
            closeAfterSelect: true,
            maxDays: 365,
            monthsToShow: 2,
            dateDisplay: function (arrival, departure) {
                return arrival;
            },
            minDate: new Date()
        },
        datepickerOptions: {
            days: days,
            monthsShort: monthsShort,
            months: months,
            daysShort: daysShort
        },
        extend: {},
        vue: {},
        dateSuffix: dateSuffix,
        errorStyle: errorStyle,
        defaultChildAge: 0
    };

    config = setDefaultsIfNotSet(config, defaultSettings);

    /**
     * Availability search data can be set via either the availabilitySearch config
     * or individually (This is to be backward compatible with older configs)
     */
    for (let queryString in mapAvailabilitySearch)
    {
        const requestValue = config.availabilitySearch[queryString] ?? null;
        if (requestValue) {
            config = set(
                config,
                mapAvailabilitySearch[queryString],
                requestValue
            );
        }
    }

    /**
     * Overwrite simple setting
     */
    const overwriteSettings = {
        maxChildren: function () {
            let maxChildren = {};
            for (let i = 1; i <= config.rooms; i++) {
                maxChildren[i] = config.maxChildren;
            }
            return maxChildren;
        }
    }

    for (let key in overwriteSettings) {
        config[key] = overwriteSettings[key]();
    }
    config.maxInfants = config.maxChildren;

    // room selection
    const fillSections = [
        {
            selected: 'selectedAdults',
            min: 'minAdults',
            max: 'maxAdults'
        },
        {
            selected: 'selectedChildren',
            min: 'minChildren',
            max: 'maxChildren'
        },
        {
            selected: 'selectedInfants',
            min: 'minChildren', // same a children
            max: 'maxChildren' // same a children
        }
    ];
    fillSections.forEach(s => {
        config[s.selected] = (function () {
            let obj = {};
            for (let i = 1; i <= config.rooms; ++i) {
                if ( ! config[s.selected] || ! config[s.selected][i]) {
                    obj[i] = parseInt(config[s.min]);
                } else {
                    obj[i] = parseInt(config[s.selected][i]);
                }
                if (obj[i] > config[s.max]) {
                    console.error('%c The "' + s.selected + '" is greater than the max "'+ s.max + '" allowed', config.errorStyle);
                }
            }
            return obj;
        })();
    });

    /**
     * @Helpers
     */
    spacetime.extend({
        dateMonthYearFormat() {
            let date = config.datepickerOptions.daysShort[this.day()] + ' ';
            date += this.date() + dateSuffix(this.date());
            date += ' ' + this.format('month-short');
            date += ' ' + this.year();
            return date;
        }
    });
    window.spacetime = spacetime;

    return config;
}
